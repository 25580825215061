import CustomDialog from "../CustomDialog";
import { downloadGenome } from "../../api/hub";

const GenomeDownloadModal = ({
  downloadOpen,
  setDownloadOpen,
  genome,
  accessToken,
  setError,
}) => {
  const handleConfirmDownload = async () => {
    try {
      await downloadGenome(genome.genome_id, genome.genome_title, accessToken);
    } catch (err) {
      console.error(err);
      setError(
        "An error occurred downloading the genome. Please reload if issues persist."
      );
    }
  };

  return (
    <CustomDialog
      isOpen={downloadOpen}
      handleClose={() => setDownloadOpen(false)}
      cancelAction={() => setDownloadOpen(false)}
      confirmAction={handleConfirmDownload}
      header="Download Genome"
      richText={
        <>
          Download <strong>{genome.genome_title}</strong> as a JSON that you can
          use with a local instance of FEAGI. Have fun exploring, or improve it
          and share it with the community!
        </>
      }
    />
  );
};

export default GenomeDownloadModal;
