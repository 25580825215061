// EASY USE:
// const [signupModalOpen, setSignupModalOpen] = useState(false);

// << a link/button that sets signupModalOpen to true on click >>

// {signupModalOpen && (
//     <SignupModal
//       open={signupModalOpen}
//       setOpen={setSignupModalOpen}
//       authType="signin" <-- change depending. can leave blank to use signup
//       afterAuthPath="/new-experiment" <-- change depending. can leave blank to use current location
//     />
//   )}

import { Dialog, DialogContent, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import AuthSection from "../components/auth/AuthSection";
import { usePathname } from "next/navigation";

const SignupModal = ({ open, setOpen, authType, afterAuthPath }) => {
  const pathname = usePathname();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="signup-modal"
      aria-describedby="modal-for-signup-or-login"
      PaperProps={{
        style: {
          backgroundColor: "black",
          backgroundImage: "none",
        },
      }}
    >
      <DialogContent sx={{ position: "relative" }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
        <AuthSection
          bgcolor="default"
          size="auto"
          authType={authType || "signup"}
          providers={["google", "github"]}
          afterAuthPath={
            afterAuthPath || pathname || window.location.pathname || "/lab"
          }
          isBusiness={false}
          hideFooter={false}
          handleCloseModal={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SignupModal;
