import { apiRequestExternal } from "./apiRequestExternal";
import { apiValidateArguments } from "./apiValidateArguments";
import { apiFilterNonNull } from "./apiFilterNonNull";
import { apiRemoveSpaces } from "./apiRemoveSpaces";
import { logger } from "../util/logger";

// optional queries: skip, limit
// optional params: embodiment_id, environmentId, capabilities, species, createdBy, keyword, creation_date_range, modification_date_range, rating
// get all genomes, paginated
export async function getGenomeById({
  accessToken,
  genomeId = "",
  embodimentId = "",
  environmentId = "",
}) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing token.");

    if (!genomeId && !embodimentId && !environmentId) {
      throw new Error("At least one ID is required to query.");
    }

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/genomes/genome?genome_id=${genomeId}`,
      "GET",
      null,
      {
        Authorization: accessToken,
      }
    );

    logger("Response in getGenomeById function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getGenomeById function:", error);
    throw error;
  }
}

export async function getGenomes({
  accessToken,
  skip = 0,
  limit = 10,
  sortBy,
  search,
  filters,
  displayName,
}) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Filter to only pass provided args in request body
    const body = apiFilterNonNull({
      search,
      filters,
    });

    let url = `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/genomes/query?skip=${skip}&limit=${limit}`;

    if (sortBy) {
      sortBy = apiRemoveSpaces(sortBy);
      url += `&sort_by=${sortBy}`;
    }

    if (displayName) {
      url += `&display_name=${displayName}`;
    }

    // Return custom response data
    const response = await apiRequestExternal(url, "POST", body, {
      Authorization: accessToken,
    });

    logger("Response in getGenomes function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getGenomes function:", error);
    throw error;
  }
}

// Public genomes endpoint (no auth token req'd)
export async function getGenomesPublic({
  token = process.env.NEXT_PUBLIC_PROJECT_ID, // default to admin token
  skip = 0,
  limit = 10,
  sortBy,
  search,
  filters,
  displayName,
}) {
  try {
    // Validate args
    if (!token) throw new Error("Missing access/admin token.");

    // Filter to only pass provided args in request body
    const body = apiFilterNonNull({
      search,
      filters,
    });

    let url = `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/global/hub/browse?skip=${skip}&limit=${limit}`;

    if (sortBy) {
      sortBy = apiRemoveSpaces(sortBy);
      url += `&sort_by=${sortBy}`;
    }

    if (displayName) {
      url += `&display_name=${displayName}`;
    }

    // Return custom response data
    const response = await apiRequestExternal(url, "POST", body, {
      Authorization: token,
    });

    logger("Response in getGenomesPublic function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getGenomesPublic function:", error);
    throw error;
  }
}

// Featured genomes
export async function getFeaturedGenomes({
  adminToken = process.env.NEXT_PUBLIC_PROJECT_ID,
}) {
  try {
    // Validate args
    if (!adminToken) throw new Error("Missing admin token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/global/hub/featured`,
      "POST",
      null,
      { Authorization: adminToken }
    );

    logger("Response in getFeaturedGenomes function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getFeaturedGenomes function:", error);
    throw error;
  }
}

// Edit/update genome
export async function updateGenome({
  accessToken,
  genomeId,
  title,
  features,
  description,
}) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, genomeId });

    // Structure the body with correct key names
    const requestBody = {
      genome_id: genomeId,
      genome_title: title,
      features: features,
      genome_description: description,
    };

    // Filter out null and undefined values
    const filteredBody = apiFilterNonNull(requestBody);

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/genomes/update`,
      "PUT",
      filteredBody,
      { Authorization: accessToken }
    );

    logger("Response in updateGenome function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in updateGenome function:", error);
    throw error;
  }
}

// Delete genome
export async function deleteGenome(accessToken, genomeId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, genomeId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/genomes/genome?genome_id=${genomeId}`,
      "DELETE",
      null,
      { Authorization: accessToken }
    );

    logger("Response in deleteGenome function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in deleteGenome function:", error);
    throw error;
  }
}

// Toggle whether genome is a user favorite
export async function toggleGenomeFavorite(
  accessToken,
  genomeId,
  modifications = {}
) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, genomeId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/genomes/toggle_favorite`,
      "PUT",
      {
        genome_id: genomeId,
        modifications,
      },
      { Authorization: accessToken }
    );

    logger("Response in toggleGenomeFavorite function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in toggleGenomeFavorite function:", error);
    throw error;
  }
}

// Toggle whether user's genome is public
export async function toggleGenomePublic(
  accessToken,
  genomeId,
  modifications = {}
) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, genomeId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/genomes/toggle_public`,
      "PUT",
      {
        genome_id: genomeId,
        modifications,
      },
      { Authorization: accessToken }
    );

    logger("Response in toggleGenomePublic function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in toggleGenomePublic function:", error);
    throw error;
  }
}

// Get list of genome filters
export async function getGenomeFilters(
  token = process.env.NEXT_PUBLIC_PROJECT_ID
) {
  try {
    // Validate args
    if (!token) throw new Error("Missing access/admin token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/genomes/filters`,
      "GET",
      null,
      { Authorization: token }
    );

    logger("Response in getGenomeFilters function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getGenomeFilters function:", error);
    throw error;
  }
}

// Get list of genome sort options
export async function getGenomeSortOptions(
  token = process.env.NEXT_PUBLIC_PROJECT_ID
) {
  try {
    // Validate args
    if (!token) throw new Error("Missing access/admin token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/genomes/sort_options`,
      "GET",
      null,
      { Authorization: token }
    );

    logger("Response in getGenomeSortOptions function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getGenomeSortOptions function:", error);
    throw error;
  }
}

// Add another genome to current experiment
export async function augmentGenome(accessToken, genomeId, sessionId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, genomeId, sessionId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/genomes/initiate_amalgamation`,
      "POST",
      { genome_id: genomeId, session_id: sessionId },
      { Authorization: accessToken }
    );

    logger("Response in augmentGenomes function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in augmentGenomes function:", error);
    throw error;
  }
}

// Fetch terms for public consent
export async function fetchPublicConsentTerms(accessToken) {
  try {
    apiValidateArguments({ accessToken });
    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/genomes/public_genome_consent`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in fetchPublicConsentTerms function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in fetchPublicConsentTerms function:", error);
    throw error;
  }
}

// Share a genome
export async function shareGenome(accessToken, displayName, genomeId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, genomeId, displayName });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/genomes/share`,
      "PUT",
      { genome_id: genomeId, display_name: displayName },
      { Authorization: accessToken }
    );

    logger("Response in shareGenome function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in shareGenome function:", error);
    throw error;
  }
}

// Hide a genome
export async function hideGenome(accessToken, displayName, genomeId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, genomeId, displayName });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/genomes/share`,
      "DELETE",
      { genome_id: genomeId, display_name: displayName },
      { Authorization: accessToken }
    );

    logger("Response in hideGenome function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in hideGenome function:", error);
    throw error;
  }
}
