import { logger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";
import createFile from "./apiCreateFile";
import extractFilename from "./apiExtractFilename";
import formatFilename from "./apiFormatFilename";
import { apiValidateArguments } from "./apiValidateArguments";

// Get genome by ID
export async function getHubGenomeById(
  genomeId,
  token = process.env.NEXT_PUBLIC_PROJECT_ID // default to admin token
) {
  try {
    // Validate args
    apiValidateArguments({ genomeId, token });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/global/hub/genome?genome_id=${genomeId}`,
      "GET",
      null,
      {
        Authorization: token,
      }
    );

    logger("Response in getHubGenomeById function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getHubGenomeById function:", error);
    throw error;
  }
}

// Get Hub genome download
export async function downloadGenome(
  genomeId,
  genomeTitle,
  adminToken = process.env.NEXT_PUBLIC_PROJECT_ID
) {
  try {
    // Validate args
    if (!adminToken) throw new Error("Missing admin token.");

    // Fetch without apiRequestExternal -- it was messing up the data somehow
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/download/genome?genome_id=${genomeId}`,
      {
        method: "GET",
        headers: {
          Authorization: adminToken,
          Accept: "application/json",
        },
      }
    );

    logger("Response in downloadGenome function:", response);

    if (!response) {
      throw new Error("No response returned from the download request.");
    }

    if (!response.ok) {
      const error = new Error(`Server response not OK.`);
      error.status = response.status;
      throw error;
    }

    const contentDisposition = response.headers?.get("content-disposition");

    const filename = contentDisposition
      ? extractFilename(contentDisposition)
      : `feagi_genome_${genomeTitle}`;

    await createFile(response, formatFilename(filename));

    // Handle errors
  } catch (error) {
    console.error("Error in downloadGenome function:", error);
    throw error;
  }
}
