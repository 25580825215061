import { logger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";
import { apiValidateArguments } from "./apiValidateArguments";

// list all FEAGI sessions for user
// export async function getAllFeagiSessions(accessToken, uid) {
//   try {
//     // Validate args
//     apiValidateArguments({ accessToken, uid });

//     // Return custom response data
//     const response = await apiRequestExternal(
//       `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/feagi/session/list/open_sessions/${uid}`,
//       "GET",
//       null,
//       { Authorization: accessToken }
//     );

//     logger("Response in getAllFeagiSessions function:", response);
//     return response;

//     // Handle errors
//   } catch (error) {
//     console.error("Error in getAllFeagiSessions function:", error);
//     throw error;
//   }
// }

// list active FEAGI sessions for user
// export async function getActiveFeagiSessions(accessToken, uid) {
//   try {
//     // Validate args
//     apiValidateArguments({ accessToken, uid });

//     // Return custom response data
//     const response = await apiRequestExternal(
//       `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/feagi/session/list/active_sessions/${uid}`,
//       "GET",
//       null,
//       { Authorization: accessToken }
//     );

//     logger("Response in getActiveFeagiSessions function:", response);
//     return response;

//     // Handle errors
//   } catch (error) {
//     console.error("Error in getActiveFeagiSessions function:", error);
//     throw error;
//   }
// }

// list idle FEAGI sessions for user
// export async function getIdleFeagiSessions(accessToken, uid) {
//   try {
//     // Validate args
//     apiValidateArguments({ accessToken, uid });

//     // Return custom response data
//     const response = await apiRequestExternal(
//       `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/feagi/session/list/idle_sessions/${uid}`,
//       "GET",
//       null,
//       { Authorization: accessToken }
//     );

//     logger("Response in getIdleFeagiSessions function:", response);
//     return response;

//     // Handle errors
//   } catch (error) {
//     console.error("Error in getIdleFeagiSessions function:", error);
//     throw error;
//   }
// }

// get details for a session
export async function getFeagiSessionInfo(accessToken, sessionId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, sessionId });

    // Send request & return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/feagi/session/session/${sessionId}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getFeagiSessionInfo function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getFeagiSessionInfo function:", error);
    throw error;
  }
}

// close a session
// export async function closeFeagiSession(accessToken, sessionId) {
//   try {
//     // Validate args
//     apiValidateArguments({ accessToken, sessionId });

//     // Send request & return custom response data
//     const response = await apiRequestExternal(
//       `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/feagi/session/session/${sessionId}`,
//       "PUT",
//       null,
//       { Authorization: accessToken }
//     );

//     logger("Response in closeFeagiSession function:", response);
//     return response;

//     // Handle errors
//   } catch (error) {
//     console.error("Error in closeFeagiSession function:", error);
//     throw error;
//   }
// }

// get status / health check of a session
// example response:
// {
//   "burst_engine": true,
//   "genome_availability": false,
//   "genome_validity": false,
//   "brain_readiness": false
// }
export async function getSessionFeagiStatus(accessToken, sessionId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, sessionId });

    // Send request & return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/feagi/session/feagi/health?session_id=${sessionId}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getSessionFeagiStatus function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getSessionFeagiStatus function:", error);
    throw error;
  }
}

// deploy genome in a session (if genomeId is passed, use that instead of default)
export async function deployGenome(accessToken, sessionId, genomeId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, sessionId });

    // Send request & return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/feagi/session/feagi/deploy_genome`,
      "POST",
      {
        token: accessToken,
        session_id: sessionId,
        ...(genomeId && { genome_id: genomeId }),
      },
      { Authorization: accessToken }
    );

    logger("Response in deployGenome function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in deployGenome function:", error);
    throw error;
  }
}

// When an experiment finishes starting, send stats on how long everything took
export async function sendLaunchStats({
  accessToken,
  sessionId,
  experimentCreationTime,
  feagiInstanceLaunchTime,
  genomeDeploymentTime,
  brainVisLoadTime = 1,
}) {
  try {
    // Validate args
    apiValidateArguments({
      accessToken,
      sessionId,
      experimentCreationTime,
      feagiInstanceLaunchTime,
      genomeDeploymentTime,
    });

    // Send request & return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/feagi/session/feagi/launch_stats`,
      "POST",
      {
        session_id: sessionId,
        experiment_creation: experimentCreationTime,
        feagi_instance_launch: feagiInstanceLaunchTime,
        genome_deployment: genomeDeploymentTime,
        brain_visualizer_load: brainVisLoadTime,
      },
      { Authorization: accessToken }
    );

    logger("Response in sendLaunchStats function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in sendLaunchStats function:", error);
    throw error;
  }
}

// Freeze current brain state (connectome)
export async function freezeBrain({
  accessToken,
  sessionId,
  experimentId,
  experimentGenomeId,
  freezeNote,
}) {
  try {
    // Validate args
    apiValidateArguments({
      accessToken,
      sessionId,
      experimentId,
      experimentGenomeId,
      freezeNote,
    });

    // Send request & return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/feagi/session/feagi/connectome/freeze`,
      "POST",
      {
        session_id: sessionId,
        experiment_id: experimentId,
        experiment_genome_id: experimentGenomeId,
        freeze_note: freezeNote,
      },
      { Authorization: accessToken }
    );

    logger("Response in freezeBrain function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in freezeBrain function:", error);
    throw error;
  }
}

// Revive a frozen brain state
export async function reviveBrain(
  accessToken,
  experimentId,
  connectomeId,
  freezeRunning
) {
  try {
    // Validate args
    apiValidateArguments({
      accessToken,
      experimentId,
      connectomeId,
      freezeRunning,
    });

    // Send request & return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/feagi/session/feagi/connectome/revive`,
      "POST",
      {
        session_id: "fake",
        experiment_id: experimentId,
        connectome_id: connectomeId,
        freeze_running: freezeRunning,
      },
      { Authorization: accessToken }
    );

    logger("Response in reviveBrain function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in reviveBrain function:", error);
    throw error;
  }
}

// Get experiment ID via session ID
// Response looks like { "experiment_id": "ex-10062752" }
export async function getExperimentIdBySessionId(sessionId, accessToken) {
  try {
    // Validate args
    apiValidateArguments({ sessionId, accessToken });

    // Send request & return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/feagi/associated_experiment?session_id=${sessionId}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getExperimentIdBySessionId function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getExperimentIdBySessionId function:", error);
    throw error;
  }
}
