"use client";
import { useEffect, useState } from "react";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDna } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../util/auth";
import { logger } from "../util/logger";
import { getGenomeById } from "../api/genomeManagement";
import NewExperimentGenomeCard from "./new-experiment/NewExperimentGenomeCard";
import PageLoader from "./PageLoader";

const Parentage = ({ open, handleClose, genome }) => {
  const auth = useAuth();
  const accessToken = auth.user.accessToken;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [parentGenomes, setParentGenomes] = useState([]);

  useEffect(() => {
    const fetchParentGenomes = async () => {
      try {
        setError("");
        // Confirm genome object correctly accessed
        logger("genome:", genome);
        if (!genome) {
          throw new Error("Error fetching data. Please reload and try again.");
        }
        // Check for parent IDs
        logger(genome.parentage);
        const parentIds = genome.parentage; // ex: {g-ostaeodpqe: {genetic_mechanism: "duplication", initiation_time: "2024-02-06T21:11:19.538000"}}
        if (!parentIds) {
          setMessage(
            "You're at the origin of life! This genome has no parents."
          );
        } else {
          if (!accessToken) {
            throw new Error("You must be signed in to view parentage.");
          }
          // Populate array with all parent genome objects
          const parents = [];

          for (const parentId in parentIds) {
            const res = await getGenomeById({
              accessToken,
              genomeId: parentId,
            });
            if (res.data?.genome_id) {
              parents.push(res.data);
            }
          }
          // Set parent genomes
          if (parents.length) {
            setParentGenomes(parents);
          } else {
            setError(
              "Unable to find parent genomes. Please reload and try again."
            );
          }
        }
      } catch (err) {
        // Handle errors
        console.error(err);
        setError(err.message);
      } finally {
        // Set loading to false
        setLoading(false);
      }
    };
    if (accessToken && genome) fetchParentGenomes();
  }, [accessToken, genome, setError]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="genome-title"
      sx={{
        "& .MuiPaper-root": {
          // maxWidth: { xs: "95vw", lg: "80vw" },
          // minWidth: { xs: "90vw", lg: "50vw" },
          overflow: "hidden",
        },
      }}
    >
      {/* Title */}
      <DialogTitle id="genome-title" sx={{ display: "flex" }}>
        <FontAwesomeIcon
          icon={faDna}
          style={{ color: "accents.main", marginRight: "10px" }}
        />
        <Typography mr={2}>Genome Parentage</Typography>
        <IconButton
          sx={{ position: "absolute", right: 7, top: 7 }}
          onClick={handleClose}
          size="small"
        >
          <CancelIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          "& .MuiPaper-root": {
            overflowY: "visible",
          },
        }}
      >
        {/* Error/message */}
        {error && <Alert severity="error">{error}</Alert>}
        {message && <Alert severity="info">{message}</Alert>}

        {/* Loading spinner */}
        {loading && <PageLoader />}

        {/* Genome */}
        {!loading &&
          parentGenomes.length > 0 &&
          parentGenomes.map((parentGenome) => {
            return (
              <NewExperimentGenomeCard
                key={parentGenome.genome_id}
                accessToken={accessToken}
                row={parentGenome}
                error={error}
                setError={setError}
                handleCurrentClose={handleClose}
              />
            );
          })}
      </DialogContent>
    </Dialog>
  );
};

export default Parentage;
