import Image from "next/image";
import { Children, ReactNode, isValidElement, JSX } from "react";
import { Box } from "@mui/material";
import { TipsAndUpdates } from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import { Components } from "react-markdown";
import remarkGfm from "remark-gfm";
import DOMPurify from "dompurify";
import CustomLink from "./CustomLink";

const Heading = (Tag: keyof JSX.IntrinsicElements) => {
  const Component = ({ children }: { children: ReactNode }) => (
    <Tag style={{ marginBottom: "12px", fontWeight: "500" }}>{children}</Tag>
  );
  Component.displayName = `Heading${Tag}`;
  return Component;
};

const DisplayMarkdown = ({ markdownFromDb }: { markdownFromDb: string }) => {
  const sanitizedMarkdown = DOMPurify.sanitize(markdownFromDb);

  const renderers = {
    img: ({
      src,
      alt,
      title,
      className,
    }: {
      src: string;
      alt: string;
      title?: string;
      className?: string;
    }) => {
      // If the image has a specific class or attribute indicating it's a video
      if (className === "video-embed" || alt?.includes("video")) {
        return (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <video
              muted
              controls
              playsInline
              style={{
                maxHeight: "500px",
                maxWidth: "100%",
                objectFit: "contain",
              }}
            >
              <source src={src} type="video/mp4" />
            </video>
          </Box>
        );
      }

      // Original image rendering logic
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              position: "relative",
              width: { xs: "300px", md: "500px", xl: "1000px" },
              height: { xs: "300px", md: "500px" },
            }}
          >
            <Image
              src={src}
              alt={alt}
              title={title}
              fill
              sizes="300px"
              style={{ objectFit: "contain" }}
            />
          </Box>
        </Box>
      );
    },

    video: ({ src, ...props }: { src: string; alt?: string }) => {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <video
            src={src}
            controls
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
            {...props}
          />
        </Box>
      );
    },

    a: ({ href, children }: { href: string; children: ReactNode }) => {
      return (
        <CustomLink
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "underline",
            color: "#389cb5",
            fontWeight: "500",
          }}
        >
          {children}
        </CustomLink>
      );
    },

    // Avoid potential invalid HTML nesting
    p: ({ children }: { children: ReactNode }) => {
      const hasNonTextOrSpanChildren = Children.toArray(children).some(
        (child) =>
          isValidElement(child) &&
          child.type !== "span" &&
          typeof child !== "string"
      );
      // If non-text/non-span children, use a div
      if (hasNonTextOrSpanChildren) {
        return <div>{children}</div>;
      }
      // Otherwise, use a p
      return <p>{children}</p>;
    },

    ol: ({ children }: { children: ReactNode }) => (
      <ol style={{ width: "100%", paddingLeft: 0 }}>{children}</ol>
    ),

    li: ({ children }: { children: ReactNode }) => (
      <li style={{ marginBottom: "12px", marginLeft: "20px" }}>{children}</li>
    ),

    h1: Heading("h1"),
    h2: Heading("h2"),
    h3: Heading("h3"),
    h4: Heading("h4"),
    h5: Heading("h5"),
    h6: Heading("h6"),

    blockquote: ({ children }: { children: ReactNode }) => (
      <Box
        sx={{
          p: 3,
          backgroundColor: "#8199e22b",
          borderRadius: "10px",
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <TipsAndUpdates /> {children}
      </Box>
    ),
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={renderers as Components}
    >
      {sanitizedMarkdown}
    </ReactMarkdown>
  );
};

export default DisplayMarkdown;
